'use client';

import { Script } from 'gatsby';
import React, { type FC, useEffect, useState } from 'react';

const wait = (callback: () => void): void => {
    if (window.app?.config) {
        return callback();
    }

    setTimeout(() => wait(callback), 100);
};

export const FiizyCalculator: FC = () => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        wait(() => {
            setIsLoaded(true);

            document.querySelector('#theme-toggle')?.classList.add('d-none');
            document.documentElement.classList.remove('dark-theme');
        });

        return (): void => {
            for (const element of document.head.querySelectorAll('script[src*="fiizy"], link[href*="fiizy"], style')) {
                if (element.tagName === 'STYLE' && !element.textContent?.includes('.sc-fi-')) {
                    continue;
                }

                element.remove();
            }

            location.reload();
        };
    }, []);

    if (!isLoaded) {
        return null;
    }

    return (
        <>
            <div
                id="app"
                data-fiizy-app
                data-locale="ro-RO"
                data-language={window.app.config.lang}
                data-api-url={window.app.config.apiUrl}
                data-channel-hash={window.app.config.channelHash}
                data-cdn-path="https://cdn.finzmo.com/system/web/loando/ro/"
                data-client-bundle-identifier={window.app.config.clientBundleIdentifier}
            />

            <Script type="text/javascript" src="https://cdn.fiizy.com/calculator/js/app.js" />
        </>
    );
};
